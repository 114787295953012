<template>
  <Container :horizontal-padding="false" :vertical-padding="false">
    <div :class="['content-with-sidebar', sidebarFirst && 'sidebar-first']">
      <div class="content">
        <slot />
      </div>
      <aside class="sidebar">
        <Container :vertical-padding="!hideSidebarVerticalPadding">
          <slot name="aside" />
        </Container>
      </aside>
    </div>
  </Container>
</template>

<script>
  import Container from "~/components/Container"

  export default {
    components: { Container },
    props: {
      sidebarFirst: {
        type: Boolean,
        default: false,
      },
      hideSidebarVerticalPadding: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style lang="scss">
  .content-with-sidebar {
    display: flex;
    margin-bottom: $base-spacing * 6;

    @include viewport("mini", "sm") {
      flex-direction: column;
    }

    @supports (display: grid) {
      display: grid;
      grid-template-columns: minmax(0, 1fr) 35%;
      @include viewport("mini", "sm") {
        grid-template-columns: 1fr;
      }
      @media print {
        grid-template-columns: 1fr;
      }
    }

    @media print {
      flex-direction: column;
    }
  }

  .content {
    width: calc(100% - 350px - 24px);
    margin-right: 24px;
    @include viewport("mini", "sm") {
      width: 100%;
    }
    @supports (display: grid) {
      width: 100%;
      margin-right: 0;
    }
    .sidebar-first & {
      @include viewport("mini", "sm") {
        order: 1;
      }
    }
  }

  .sidebar {
    width: 350px;

    .container {
      height: 100%;
    }

    @include viewport("mini", "sm") {
      width: 100%;
    }
    @supports (display: grid) {
      width: auto;
    }
    .sidebar-first & {
      @include viewport("mini", "sm") {
        order: 0;
      }
    }
  }
</style>
